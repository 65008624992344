export const tenantWallet = [
  {
    id: 1,
    header: 'Saving Towards Rent Due',
    dateCreated: 'dd/mm/yyy',
    dueDate: 'dd/mm/yyy',
    savingsType: 'Remove from card automatically.',
    amount: '#2.70M',
  },
  {
    id: 2,
    header: 'Saving Towards Rent Due',
    dateCreated: 'dd/mm/yyy',
    dueDate: 'dd/mm/yyy',
    savingsType: 'Remove from card automatically.',
    amount: '#2.70M',
  },
]