import payment from '../assets/house-01.png'

export const managerDocuments = [
  {
    id: 1001,
    icon: payment,
    heading: 'Payment Receipts',
  },
  {
    id: 1002,
    icon: payment,
    heading: 'Lease Agreement',
  },
  {
    id: 1003,
    icon: payment,
    heading: 'Lease Terms',
  },
]
