import React from 'react'
import BigCalendar from '../Calendar/BigCalendar'

const TenantCalendar = () => {
  return (
    <>
    <BigCalendar/>
    </>
  )
}

export default TenantCalendar