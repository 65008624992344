export const paymentSidebar = [
  {
    id: 1,
    text: 'Payments made this week.',
  },
  {
    id: 2,
    text: 'Payments made this week.',
  },
  {
    id: 3,
    text: 'Payments made this week.',
  },
  {
    id: 4,
    text: 'Payments made this week.',
  },
  {
    id: 5,
    text: 'Payments made this week.',
  },
]