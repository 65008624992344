export const tenantTransactionHistory = [
  {
    id: 101,
    date: 'dd/mm/yy',
    paymentType: 'Rent',
    amount: '#300,000.00',
    time: '10:41pm',
    pMethod: 'Bank Transfer',
  },
  {
    id: 102,
    date: 'dd/mm/yy',
    paymentType: 'Rent',
    amount: '#500,000.00',
    time: '12:00am',
    pMethod: 'Bank Transfer',
  },
  {
    id: 103,
    date: 'dd/mm/yy',
    paymentType: 'Rent',
    amount: '#500,000.00',
    time: '12:00am',
    pMethod: 'Bank Transfer',
  },
  {
    id: 104,
    date: 'dd/mm/yy',
    paymentType: 'Rent',
    amount: '#300,000.00',
    time: '10:41pm',
    pMethod: 'Bank Transfer',
  },
  {
    id: 105,
    date: 'dd/mm/yy',
    paymentType: 'Rent',
    amount: '#500,000.00',
    time: '12:00am',
    pMethod: 'Bank Transfer',
  },
  {
    id: 106,
    date: 'dd/mm/yy',
    paymentType: 'Rent',
    amount: '#500,000.00',
    time: '12:00am',
    pMethod: 'Bank Transfer',
  },
  {
    id: 107,
    date: 'dd/mm/yy',
    paymentType: 'Rent',
    amount: '#300,000.00',
    time: '10:41pm',
    pMethod: 'Bank Transfer',
  },
  {
    id: 108,
    date: 'dd/mm/yy',
    paymentType: 'Rent',
    amount: '#500,000.00',
    time: '12:00am',
    pMethod: 'Bank Transfer',
  },
  {
    id: 109,
    date: 'dd/mm/yy',
    paymentType: 'Rent',
    amount: '#500,000.00',
    time: '12:00am',
    pMethod: 'Bank Transfer',
  }, 
]
