export const tenantDueDates = [
  {
    id: 1,
    title: 'Rent Payment',
    text: 'Rent due for Mr. Kelly for month of February, 2024',
    sDate: 'dd/mm/yyyy',
    eDate: 'dd/mm/yyyy',
    amount: '#500,000.00k',
  },
  {
    id: 2,
    title: 'Electricity Bill',
    text: 'Electricity bill due for month of February, 2024',
    sDate: 'dd/mm/yyyy',
    eDate: 'dd/mm/yyyy',
    amount: '#10,000.00k',
  },
  {
    id: 3,
    title: 'Water Bill',
    text: 'Water bill due for month of February, 2024',
    sDate: 'dd/mm/yyyy',
    eDate: 'dd/mm/yyyy',
    amount: '#10,000.00k',
  },
  {
    id: 4,
    title: 'Security Bill',
    text: 'Security bill due for month of February, 2024',
    sDate: 'dd/mm/yyyy',
    eDate: 'dd/mm/yyyy',
    amount: '#10,000.00k',
  },
  {
    id: 5,
    title: 'Waste Collection Bill',
    text: 'Waste collection bill due for month of February, 2024',
    sDate: 'dd/mm/yyyy',
    eDate: 'dd/mm/yyyy',
    amount: '#10,000.00k',
  },
  {
    id: 6,
    title: 'Repair Works',
    text: 'Repair works bill due for month of February, 2024',
    sDate: 'dd/mm/yyyy',
    eDate: 'dd/mm/yyyy',
    amount: '#10,000.00k',
  },
]
