import { FcBusinessman } from 'react-icons/fc'

export const tenants = [
  {
    id: 1,
    iconTwo: <FcBusinessman size={40} />,
    user: 'Doye Amos',
    ppt: 'Occupying Unit 1, Ikeja Axis',
  },
  {
    id: 2,
    iconTwo: <FcBusinessman size={40} />,
    user: 'Doye Amos',
    ppt: 'Occupying Unit 1, Ikeja Axis',
  },
  {
    id: 3,
    iconTwo: <FcBusinessman size={40} />,
    user: 'Doye Amos',
    ppt: 'Occupying Unit 1, Ikeja Axis',
  },
  {
    id: 4,
    iconTwo: <FcBusinessman size={40} />,
    user: 'Doye Amos',
    ppt: 'Occupying Unit 1, Ikeja Axis',
  },
]
