export const landlordDueDates = [
  {
    id: 1,
    title: 'Rent Payment',
    text: 'Rent due for Mr. Kelly for month of February, 2024',
    sDate: 'dd/mm/yyyy',
    eDate: 'dd/mm/yyyy',
    amount: '#500,000.00k',
  },
  {
    id: 2,
    title: 'Rent Payment',
    text: 'Rent due for Mr. Kelly for month of February, 2024',
    sDate: 'dd/mm/yyyy',
    eDate: 'dd/mm/yyyy',
    amount: '#500,000.00k',
  },
  {
    id: 3,
    title: 'Rent Payment',
    text: 'Rent due for Mr. Kelly for month of February, 2024',
    sDate: 'dd/mm/yyyy',
    eDate: 'dd/mm/yyyy',
    amount: '#500,000.00k',
  },
  {
    id: 4,
    title: 'Rent Payment',
    text: 'Rent due for Mr. Kelly for month of February, 2024',
    sDate: 'dd/mm/yyyy',
    eDate: 'dd/mm/yyyy',
    amount: '#500,000.00k',
  },
  {
    id: 5,
    title: 'Rent Payment',
    text: 'Rent due for Mr. Kelly for month of February, 2024',
    sDate: 'dd/mm/yyyy',
    eDate: 'dd/mm/yyyy',
    amount: '#500,000.00k',
  },
  {
    id: 6,
    title: 'Rent Payment',
    text: 'Rent due for Mr. Kelly for month of February, 2024',
    sDate: 'dd/mm/yyyy',
    eDate: 'dd/mm/yyyy',
    amount: '#500,000.00k',
  },
  {
    id: 7,
    title: 'Rent Payment',
    text: 'Rent due for Mr. Kelly for month of February, 2024',
    sDate: 'dd/mm/yyyy',
    eDate: 'dd/mm/yyyy',
    amount: '#500,000.00k',
  },
  {
    id: 8,
    title: 'Rent Payment',
    text: 'Rent due for Mr. Kelly for month of February, 2024',
    sDate: 'dd/mm/yyyy',
    eDate: 'dd/mm/yyyy',
    amount: '#500,000.00k',
  },
  {
    id: 9,
    title: 'Rent Payment',
    text: 'Rent due for Mr. Kelly for month of February, 2024',
    sDate: 'dd/mm/yyyy',
    eDate: 'dd/mm/yyyy',
    amount: '#500,000.00k',
  },
]